import React from "react";
import "./shapes.css";

function ScoreTable({ players, scores, holes, metres, strokeIndex, par, markerColor, isNineHoles }) {
  // Helper function to calculate sums
  const calculateSums = (array, limit = 18) => {
    const front9 = array.slice(0, 9).reduce((sum, val) => sum + val, 0);
    const back9 = limit === 18 ? array.slice(9, 18).reduce((sum, val) => sum + val, 0) : 0;
    const total = front9 + back9;
    return { front9, back9, total };
  };

  const { front9: metresOut, back9: metresIn, total: totalMetres } = calculateSums(metres, isNineHoles ? 9 : 18);
  const { front9: parOut, back9: parIn, total: totalPar } = calculateSums(par, isNineHoles ? 9 : 18);

  // Determine Background Shape
  const ShapeBackground = ({ score, par }) => {
    if (score === par) return <div className="shape"></div>; // Par - No background
    if (score === par - 1) return <div className="shape circle"></div>; // Birdie - Circle
    if (score <= par - 2) return <div className="shape solid-circle"></div>; // Eagle or better
    if (score === par + 1) return <div className="shape square"></div>; // Bogey - Square
    if (score >= par + 2) return <div className="shape solid-square"></div>; // Double-bogey or worse
    return <div className="shape"></div>;
  };

  const cellStyle = {
    border: "1px solid black",
    padding: "5px",
    textAlign: "center",
    verticalAlign: "middle",
    position: "relative",
    fontSize: "20px",
  };

  return (
    <div style={{ overflowX: "auto", marginTop: "20px" }}>
      <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}>
        <thead>
          <tr style={{ backgroundColor: "darkgreen", color: "white" }}>
            <th style={cellStyle}>Hole</th>
            {holes.slice(0, 9).map((hole) => (
              <th key={hole} style={cellStyle}>{hole}</th>
            ))}
            <th style={cellStyle}>Out</th>

            {!isNineHoles && (
              <>
                {holes.slice(9, 18).map((hole) => (
                  <th key={hole} style={cellStyle}>{hole}</th>
                ))}
                <th style={cellStyle}>In</th>
                <th style={cellStyle}>Tot</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {/* Metres Row */}
          <tr style={{ backgroundColor: markerColor }}>
            <td style={cellStyle}>Metres</td>
            {metres.slice(0, 9).map((metre, idx) => (
              <td key={`metre-front-${idx}`} style={cellStyle}>{metre}</td>
            ))}
            <td style={cellStyle}>{metresOut}</td>

            {!isNineHoles && (
              <>
                {metres.slice(9, 18).map((metre, idx) => (
                  <td key={`metre-back-${idx}`} style={cellStyle}>{metre}</td>
                ))}
                <td style={cellStyle}>{metresIn}</td>
                <td style={cellStyle}>{totalMetres}</td>
              </>
            )}
          </tr>

          {/* Stroke Index Row */}
          <tr style={{ backgroundColor: "darkgreen", color: "white" }}>
            <td style={cellStyle}>Stroke Index</td>
            {strokeIndex.slice(0, 9).map((index, idx) => (
              <td key={`index-front-${idx}`} style={cellStyle}>{index}</td>
            ))}
            <td style={cellStyle}></td>

            {!isNineHoles && (
              <>
                {strokeIndex.slice(9, 18).map((index, idx) => (
                  <td key={`index-back-${idx}`} style={cellStyle}>{index}</td>
                ))}
                <td style={cellStyle}></td>
                <td style={cellStyle}></td>
              </>
            )}
          </tr>

          {/* Par Row */}
          <tr>
            <td style={cellStyle}>Par</td>
            {par.slice(0, 9).map((p, idx) => (
              <td key={`par-front-${idx}`} style={cellStyle}>{p}</td>
            ))}
            <td style={cellStyle}>{parOut}</td>

            {!isNineHoles && (
              <>
                {par.slice(9, 18).map((p, idx) => (
                  <td key={`par-back-${idx}`} style={cellStyle}>{p}</td>
                ))}
                <td style={cellStyle}>{parIn}</td>
                <td style={cellStyle}>{totalPar}</td>
              </>
            )}
          </tr>

          {/* Player Rows */}
          {players.map((player, idx) => {
            const { front9, back9, total } = calculateSums(scores[idx], isNineHoles ? 9 : 18);
            return (
              <tr key={player}>
                <td style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>{player}</td>

                {scores[idx].slice(0, 9).map((score, holeIdx) => (
                  <td key={`score-front-${idx}-${holeIdx}`} style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>
                    <ShapeBackground score={score} par={par[holeIdx]} />
                    <div className="score-number">{score}</div>
                  </td>
                ))}
                <td style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>{front9}</td>

                {!isNineHoles && (
                  <>
                    {scores[idx].slice(9, 18).map((score, holeIdx) => (
                      <td key={`score-back-${idx}-${holeIdx}`} style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>
                        <ShapeBackground score={score} par={par[holeIdx + 9]} />
                        <div className="score-number">{score}</div>
                      </td>
                    ))}
                    <td style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>{back9}</td>
                    <td style={{ ...cellStyle, fontFamily: "MessyBen", fontWeight: "bold" }}>{total}</td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ScoreTable;