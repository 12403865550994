import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  annotationPlugin
);

const ScoreDistributionChart = ({ pieScore }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Fetch score distribution from the backend
    fetch(
      "https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/pies/scoredistribution"
    )
      .then((response) => response.json())
      .then((data) => {
        const labels = Object.keys(data.scoreDistribution).map(Number); // Extract bins as labels
        const frequencies = Object.values(data.scoreDistribution); // Extract frequencies

        setChartData({
          labels,
          datasets: [
            {
              label: "Score Distribution",
              data: frequencies,
              borderColor: "rgba(75, 192, 192, 1)", // Line color
              backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill under the line
              tension: 0.4, // Smoother line curve
              fill: true,
              pointRadius: 0, // Hide individual points
            },
          ],
        });
      })
      .catch((error) =>
        console.error("Error fetching score distribution:", error)
      );
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true, // Maintain the aspect ratio
    aspectRatio: 2, // Adjust aspect ratio (width/height)
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
      annotation: {
        annotations: {
          verticalLine: {
            type: "line",
            xMin: pieScore || 0, // Default to 0 if pieScore is undefined
            xMax: pieScore || 0,
            borderColor: "red",
            borderWidth: 2,
            label: {
              display: true,
              content: `${pieScore || 0}`, // Show only the score value
              position: {
                x: "end", // Position slightly to the right
                y: "center", // Keep it vertically centered
              },
              xAdjust: 15, // Move the label 15px to the right
              color: "red", // Text color for the label
              backgroundColor: "transparent", // Remove black box
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove grid lines
        },
        ticks: {
          display: false, // Remove axis labels
        },
        title: {
          display: true, // Show title
          text: "Score", // X-axis title
          font: {
            size: 14,
            weight: "bold",
          },
          padding: { top: 10 }, // Add spacing between axis and title
        },
      },
      y: {
        grid: {
          display: false, // Remove grid lines
        },
        ticks: {
          display: false, // Remove axis labels
        },
      },
    },
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0px auto" }}>
      {chartData ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>Loading score distribution...</p>
      )}
    </div>
  );
};

export default ScoreDistributionChart;