import React from "react";
import "./ProgressBar.css";

function ProgressBar({ title, value }) {
  // Calculate the percentage for the score
  const percentage = (value / 5) * 100;

  return (
    <div className="progress-bar-container">
      <span className="progress-title">{`${title}: ${value}/5`}</span>
      <div className="progress-bar">
        {/* Full gradient background */}
        <div
          className="progress-bar-fill"
          style={{
            width: "100%", // Always fill the background
          }}
        ></div>
        {/* Clipping overlay for the unfilled portion */}
        <div
          className="progress-bar-overlay"
          style={{
            width: `${100 - percentage}%`, // Overlay the unfilled portion
            left: `${percentage}%`, // Position the overlay to match the filled percentage
          }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;