import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as atlas from "azure-maps-control";
import BakeryPanel from "./BakeryCode/BakeryPanel";
import GolfPanel from "./GolfCode/GolfPanel";
import LoadingScreen from "./LoadingScreen";

function AppContent() {
  const mapRef = useRef(null);
  const panelRef = useRef(null);
  const [bakeries, setBakeries] = useState([]);
  const [golfclubs, setGolfClubs] = useState([]);
  const [selectedBakery, setSelectedBakery] = useState(null);
  const [selectedGolfClub, setSelectedGolfClub] = useState(null);
  const [isPanelVisible, setPanelVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // Map initialization and logic
  useEffect(() => {
    if (!loading && !mapRef.current && location.pathname === "/") {
      const map = new atlas.Map("map", {
        center: [153.6, -28.7],
        zoom: 8,
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
        },
        showFeedbackLink: false,
        showLogo: false,
      });

      map.events.add("ready", () => {
        const bakeryDataSource = new atlas.source.DataSource();
        const golfClubDataSource = new atlas.source.DataSource();

        map.sources.add(bakeryDataSource);
        map.sources.add(golfClubDataSource);

        map.imageSprite
          .add("bakery-icon", "/icons/Bakery.png")
          .then(() => map.imageSprite.add("golfclub-icon", "/icons/GolfClub.png"))
          .then(() => {
            const bakeryLayer = new atlas.layer.SymbolLayer(bakeryDataSource, null, {
              iconOptions: {
                image: "bakery-icon",
                anchor: "center",
                allowOverlap: true,
                size: 0.05,
              },
            });

            const golfClubLayer = new atlas.layer.SymbolLayer(golfClubDataSource, null, {
              iconOptions: {
                image: "golfclub-icon",
                anchor: "center",
                allowOverlap: true,
                size: 0.05,
              },
            });

            map.layers.add(bakeryLayer);
            map.layers.add(golfClubLayer);

            // Click event handlers
            map.events.add("click", bakeryLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                const properties = shape.getProperties();
                handleBakeryClick(properties.ID);
              }
            });

            map.events.add("click", golfClubLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                const properties = shape.getProperties();
                handleGolfClubClick(properties.ID);
              }
            });

            // Fetch bakeries and golf clubs
            fetchBakeries(bakeryDataSource);
            fetchGolfClubs(golfClubDataSource);
          });
      });

      mapRef.current = map;
    }

    const handleOutsideClick = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setPanelVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [loading, location.pathname]);

  const fetchBakeries = (dataSource) => {
    fetch("https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakeries")
      .then((response) => response.json())
      .then((data) => {
        setBakeries(data);
        const bakeryFeatures = data.map((bakery) =>
          new atlas.data.Feature(
            new atlas.data.Point([bakery.BakeryLong, bakery.BakeryLat]),
            {
              Type: "Bakery",
              ID: bakery.BakeryID,
              Name: bakery.BakeryName,
            }
          )
        );
        dataSource.add(bakeryFeatures);
      })
      .catch((error) => console.error("Error fetching bakeries:", error));
  };

  const fetchGolfClubs = (dataSource) => {
    fetch("https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/golfclubs")
      .then((response) => response.json())
      .then((data) => {
        setGolfClubs(data);
        const golfClubFeatures = data.map((golfclub) =>
          new atlas.data.Feature(
            new atlas.data.Point([golfclub.GolfClubLong, golfclub.GolfClubLat]),
            {
              Type: "GolfClub",
              ID: golfclub.GolfClubID,
              Name: golfclub.GolfClubName,
            }
          )
        );
        //dataSource.add(golfClubFeatures);
      })
      .catch((error) => console.error("Error fetching golf clubs:", error));
  };

  const handleBakeryClick = (bakeryId) => {
    fetch(`https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakery/${bakeryId}`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedBakery({
          ...data,
          BakeryImages: [],
          Pies: data.Pies.map((pie) => ({ ...pie, PiePictures: [] })),
        });
        setSelectedGolfClub(null);
        setPanelVisible(true);

        fetch(`https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakery/${bakeryId}/images`)
          .then((response) => response.json())
          .then((imageData) => {
            setSelectedBakery((prev) => ({
              ...prev,
              BakeryImages: imageData.BakeryImages.map((img) => `data:image/jpeg;base64,${img}`),
              Pies: prev.Pies.map((pie) => ({
                ...pie,
                PiePictures:
                  imageData.PieImages.find((p) => p.PieID === pie.PieID)?.Images.map(
                    (img) => `data:image/jpeg;base64,${img}`
                  ) || [],
              })),
            }));
          });
      });
  };

  const handleGolfClubClick = (golfClubId) => {
    fetch(`https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/golf/${golfClubId}`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedGolfClub(data);
        setSelectedBakery(null);
        setPanelVisible(true);
      })
      .catch((error) => console.error("Error fetching golf club details:", error));
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {location.pathname === "/" && <div id="map" style={{ width: "100vw", height: "100vh" }} />}
      {selectedBakery && (
        <BakeryPanel
          isVisible={isPanelVisible}
          selectedBakery={selectedBakery}
          panelRef={panelRef}
        />
      )}
      {selectedGolfClub && (
        <GolfPanel
          isVisible={isPanelVisible}
          golfClubData={selectedGolfClub}
          panelRef={panelRef}
        />
      )}
    </div>
  );
}

export default AppContent;