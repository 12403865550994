import React, { useEffect, useState } from "react";
import "./styles.css";

const ScrollingBanner = () => {
  const [supporters, setSupporters] = useState([]);

  useEffect(() => {
    // Fetch supporters data from the backend
    const fetchSupporters = async () => {
      try {
        const response = await fetch("https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/saucesupporters");
        const data = await response.json();

        if (data.supporters) {
          setSupporters(data.supporters);
        }
      } catch (error) {
        console.error("Error fetching supporters data:", error);
      }
    };

    fetchSupporters();
  }, []);

  return (
    <div className="tomato-banner-container">
  <div className="tomato-marquee">
    {supporters.map((supporter, index) => (
      <span key={index}>
        {supporter.Name} {supporter.Postcode}
        {index < supporters.length - 1 && <>&nbsp;•&nbsp;</>}
      </span>
    ))}
  </div>
</div>
  );
};

export default ScrollingBanner;