import React from "react";
import HeaderCard from "./HeaderCard";
import ScoreTable from "./ScoreTable";

const GolfPanel = ({ isVisible, golfClubData, panelRef }) => {
  // Log the props to debug their values
  console.log("GolfPanel props:", { isVisible, golfClubData });

  if (!isVisible || !golfClubData) {
    return null;
  }

  const {
    courseName,
    dateOfPlay,
    markerColor,
    holesPlayed,
    players,
    metres,
    strokeIndex,
    par,
    scores,
    mapImage,
  } = golfClubData;

  return (
    <div ref={panelRef} className="panel visible">
      <HeaderCard
        courseName={courseName}
        dateOfPlay={dateOfPlay}
        markerColor={markerColor}
        players={players}
        mapImage={mapImage}
      />
      <ScoreTable
        players={players}
        scores={scores}
        holes={Array.from({ length: 18 }, (_, i) => i + 1)} // Generate holes dynamically
        metres={metres}
        strokeIndex={strokeIndex}
        par={par}
        markerColor={markerColor}
        isNineHoles={holesPlayed === 9}
      />
    </div>
  );
};

export default GolfPanel;