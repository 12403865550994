import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppContent from "./AppContent";
import TomatoSaucePage from "./TomatoSauceCode/ThreeDScene";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Map Page */}
        <Route path="/" element={<AppContent />} />
        {/* Tomato Sauce Page */}
        <Route path="/tomatosauce" element={<TomatoSaucePage />} />
      </Routes>
    </Router>
  );
}

export default App;