import React, { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import AlphabetSpinner from "./AlphabetSpinner";
import ScrollingBanner from "./ScrollingBanner";
import "./styles.css";

const TomatoSauceModel = () => {
  const gltf = useGLTF("/models/tomatosauce.glb");
  const modelRef = useRef();

  // Animate the model to rotate on all axes
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.x += 0.005; // Slow rotation
      modelRef.current.rotation.y += 0.005;
    }
  });

  return (
    <primitive
      ref={modelRef}
      object={gltf.scene}
      scale={[0.7, 0.7, 0.7]}
      position={[0, 1, 0]} // Elevated above the ground
      rotation={[Math.PI, 0 , 0]} // Initial Z-axis rotation
      castShadow // Enables shadow casting
    />
  );
};

const ThreeDScene = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const firstName = event.target.firstName.value;
    const postcode = event.target.postcode.value;

    try {
      const response = await fetch("https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/saucesupporters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Name: firstName, Postcode: postcode }),
      });

      if (response.ok) {
        alert("Supporter added successfully!");
      } else {
        alert("Failed to add supporter.");
      }
    } catch (error) {
      console.error("Error submitting supporter:", error);
      alert("An error occurred.");
    }
  };

  const handleBackClick = () => {
    window.location.href = "https://thepiereviewshow.com.au/"; // Replace with your desired URL
  };

  return (
    <div className="tomato-container">
      <div className="tomato-header">
        <button className="tomato-main-menu-button" onClick={handleBackClick}>
          <span className="tomato-main-menu-text">Main Menu</span>
        </button>
        <h1 className="tomato-h1">MAKE TOMATO SAUCE FREE</h1>
      </div>

      <div className="tomato-canvas-crop">
        <div className="tomato-canvas-container">
          <div className="tomato-halo"></div>
          <Canvas shadows camera={{ position: [0, 1, 10], fov: 45 }}>
            <ambientLight intensity={0.5} />
            <directionalLight
              castShadow
              intensity={1.0}
              position={[0, 1, 10]}
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              shadow-camera-near={0.1}
              shadow-camera-far={50}
            />
            <TomatoSauceModel />
          </Canvas>
        </div>
      </div>

      <div className="tomato-subtitle">
        <AlphabetSpinner />
      </div>

      <ScrollingBanner />

      <form className="tomato-input-container" onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" className="tomato-input-box" required />
        <input type="text" name="postcode" placeholder="Postcode" className="tomato-input-box" required />
        <button type="submit" className="tomato-submit-button">Submit</button>
      </form>
    </div>
  );
};

export default ThreeDScene;