import React, { useState, useEffect } from 'react';

const AlphabetSpinner = () => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""); // Array of letters A-Z
  const [currentLetters, setCurrentLetters] = useState(["C", "S"]); // Start with both letters as "C"
  const [targetLetters, setTargetLetters] = useState(["S", "C"]); // Target letters
  const [isAnimating, setIsAnimating] = useState(true); // Animation state

  useEffect(() => {
    if (isAnimating) {
      const interval = setInterval(() => {
        setCurrentLetters((prev) =>
          prev.map((letter, index) => {
            const currentIndex = alphabet.indexOf(letter);
            const targetIndex = alphabet.indexOf(targetLetters[index]);

            // Move forward or backward depending on the target
            if (currentIndex < targetIndex) {
              return alphabet[currentIndex + 1];
            } else if (currentIndex > targetIndex) {
              return alphabet[currentIndex - 1];
            } else {
              return letter; // Stay at the target letter
            }
          })
        );

        // Check if animation is complete
        if (
          currentLetters.every(
            (letter, index) =>
              alphabet.indexOf(letter) === alphabet.indexOf(targetLetters[index])
          )
        ) {
          clearInterval(interval); // Stop animation
          setIsAnimating(false);

          // Wait 1.5 seconds before toggling back
          setTimeout(() => {
            setIsAnimating(true);
            setTargetLetters((prev) =>
              prev[0] === "S" ? ["C", "S"] : ["S", "C"]
            ); // Toggle between "Cause" and "Sauce"
          }, 1000); // Delay for 1.5 seconds
        }
      }, 50); // Faster animation timing (adjust as needed)

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [isAnimating, alphabet, targetLetters, currentLetters]);

  return (
    <div>
      <h1 className="tomato-h1">
        Support the{" "}
        <span className="tomato-letter">{currentLetters[0]}</span>
        au
        <span className="tomato-letter">{currentLetters[1]}</span>
        e
      </h1>
    </div>
  );
};

export default AlphabetSpinner;