// src/components/HeaderCard.js
import React from "react";

function HeaderCard({ courseName, dateOfPlay, markerColor, players,mapImage }) {
  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "20px",
  };
  return (
    <div style={{ padding: "20px", background: "#f5f5f5", borderRadius: "8px" }}>
      <h2>{courseName}</h2>
      <p><strong>Date of Play:</strong> {dateOfPlay}</p>
      {/* Display the dynamically passed Map Image */}
      {mapImage && <img src={mapImage} alt="Golf Course Map" style={imageStyle} />}
    </div>
  );
}

export default HeaderCard;