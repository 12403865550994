import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

// Register the radar chart components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const PieStatistics = ({ statistics }) => {
  const [averageStatistics, setAverageStatistics] = useState(null);

  useEffect(() => {
    // Fetch average statistics from the backend
    fetch(
      "https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/pies/averagestatistics"
    )
      .then((response) => response.json())
      .then((data) => {
        setAverageStatistics(data.averageStatistics);
      })
      .catch((error) =>
        console.error("Error fetching average statistics:", error)
      );
  }, []);

  // Prepare data for the radar chart
  const data = {
    labels: [
      "Filling Generosity",
      "Value for Money",
      "Presentation",
      "Burned Mouth Factor",
      "Hunger Satisfaction",
      "Sogginess",
      "Eating Difficulty",
      "Exoticness",
    ],
    datasets: [
      // Average statistics as the background dataset
      averageStatistics && {
        label: "Average Statistics",
        data: [
          averageStatistics.FillingGenerosity,
          averageStatistics.ValueForMoney,
          averageStatistics.Presentation,
          averageStatistics.BurnedMouthFactor,
          averageStatistics.HungerSatisfaction,
          averageStatistics.Sogginess,
          averageStatistics.EatingDifficulty,
          averageStatistics.Exoticness,
        ],
        backgroundColor: "rgba(200, 200, 200, 0.2)", // Light gray fill
        borderColor: "rgba(200, 200, 200, 1)", // Gray border
        borderWidth: 2,
        pointBackgroundColor: "rgba(200, 200, 200, 1)",
      },
      // Current pie's statistics
      {
        label: "Pie Statistics",
        data: [
          statistics.FillingGenerosity,
          statistics.ValueForMoney,
          statistics.Presentation,
          statistics.BurnedMouthFactor,
          statistics.HungerSatisfaction,
          statistics.Sogginess,
          statistics.EatingDifficulty,
          statistics.Exoticness,
        ],
        backgroundColor: "rgba(0, 123, 255, 0.2)", // Light blue fill
        borderColor: "rgba(0, 123, 255, 1)", // Blue border
        borderWidth: 2,
        pointBackgroundColor: "rgba(0, 123, 255, 1)",
      },
    ].filter(Boolean), // Filter out `null` datasets if averageStatistics is not yet loaded
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
      tooltip: {
        enabled: false, // Disable hover tooltips
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
          display: false, // Hide radial axis labels (optional, for a cleaner look)
        },
        grid: {
          color: "rgba(200, 200, 200, 0.5)", // Optional: customize grid color
        },
        angleLines: {
          color: "rgba(200, 200, 200, 0.5)", // Optional: customize angle lines
        },
      },
    },
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0px auto" }}>
      <Radar data={data} options={options} />
    </div>
  );
};

export default PieStatistics;