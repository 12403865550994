import React from "react";
import ProgressBar from "./ProgressBar";
import PieStatistics from "./PieStatistics"; // Import the radar chart component
import ScoreDistributionChart from "./ScoreDistributionChart";

const BakeryPanel = ({ isVisible, selectedBakery, panelRef }) => {
  if (!isVisible || !selectedBakery) {
    return null;
  }

  return (
    <div ref={panelRef} className="panel visible">
      <div className="bakery-container">
        {/* Bakery Details */}
        <h1 className="bakery-name">{selectedBakery.BakeryName}</h1>
        <p className="bakery-address">
          {selectedBakery.BakeryAddress || "N/A"}
        </p>
        <p>
  Tomato Sauce:{" "}
  {console.log("Tomato Sauce Value:", selectedBakery.TomatoSauce)}
  {selectedBakery.TomatoSauce === "0"
    ? "0¢"
    : selectedBakery.TomatoSauce
    ? `${Math.round(selectedBakery.TomatoSauce * 100)}¢`
    : "N/A"}
</p>

        {/* Bakery Images */}
        <div className="horizontal-scroll">
          {selectedBakery.BakeryImages.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Bakery image ${index + 1}`}
              className="bakery-image"
            />
          ))}
        </div>

        {/* Pies */}
        <h2>Pies</h2>
        <div className="pies-list">
          {selectedBakery.Pies.map((pie, index) => (
            <div key={index} className="pie-item">
              <h3 className="pie-name">{pie.PieName}</h3>
              <p className="pie-price">Price: ${pie.PiePrice?.toFixed(2)}</p>
              <p className="pie-price">Price: ${pie.PiePrice?.toFixed(2)}</p>

              {/* Pie Images */}
              <div className="horizontal-scroll">
                {pie.PiePictures.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Pie image ${index + 1}`}
                    className="pie-image"
                  />
                ))}
              </div>

              {/* Pie Progress Bars */}
              <ProgressBar title="Filling Generosity" value={pie.FillingGenerosity} />
              <ProgressBar title="Value for Money" value={pie.ValueForMoney} />
              <ProgressBar title="Presentation" value={pie.Presentation} />
              <ProgressBar title="Burned Mouth Factor" value={pie.BurnedMouthFactor} />
              <ProgressBar title="Hunger Satisfaction" value={pie.HungerSatisfaction} />
              <ProgressBar title="Sogginess" value={pie.Sogginess} />
              <ProgressBar title="Eating Difficulty" value={pie.EatingDifficulty} />
              <ProgressBar title="Exoticness" value={pie.Exoticness} />

              <p className="pie-comments">Comments: {pie.PieComments}</p>
              {/* Radar Chart Section */}
      <h4>Statistics</h4>
      <div className="chart-container">
  <div className="chart-wrapper">
    <ScoreDistributionChart pieScore={pie.PieRatingNum} />
  </div>
  <div className="chart-wrapper">
    <PieStatistics
      statistics={{
        FillingGenerosity: pie.FillingGenerosity,
        ValueForMoney: pie.ValueForMoney,
        Presentation: pie.Presentation,
        BurnedMouthFactor: pie.BurnedMouthFactor,
        HungerSatisfaction: pie.HungerSatisfaction,
        Sogginess: pie.Sogginess,
        EatingDifficulty: pie.EatingDifficulty,
        Exoticness: pie.Exoticness,
      }}
    />
  </div>
</div>
        
      </div>

          ))}
        </div>
      </div>
    </div>
  );
};

export default BakeryPanel;